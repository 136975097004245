
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Home extends Vue {
  public hover1 = false;
  public hover2 = false;
  public sendToLink(link:string){
    this.$router.push(link)
  }
}
